<template>

    <teleport to="head">
        <title>{{ txt[prefs.lang].profile }} – PLANTA</title>
    </teleport>

    <template v-if="me">

        <h2 v-if="user">{{ me.firstname }} {{ me.lastname }}</h2>
        <h2 v-else>Hi, {{ me.firstname }}! 🌿</h2>

        <div v-if="me.id > 0">

            <p>{{ txt[prefs.lang].loyalty_bonus }}: <b>{{ me.credit }} грн</b>
                <span v-if="me.credit_till"> (до {{ me.credit_till }})</span>
                <el-popover
                    placement="bottom"
                    :width="250"
                    trigger="hover"
                    :title=txt[prefs.lang].loyalty_bonus
                    :content=txt[prefs.lang].loyalty_bonus_i
                >
                    <template #reference>
                        <el-button icon="el-icon-info" type="text"></el-button>
                    </template>
                </el-popover>&nbsp;
            </p>
            <p>{{ txt[prefs.lang].commethod.t }}:
    <!--            <template v-if="me.commethod">-->
                    {{ txt[prefs.lang].commethod[commethod.source_id].t }} ({{ commethod.username }})
    <!--            </template>-->
    <!--            <template v-else>-->
    <!--                E-mail ({{ me.email }})-->
    <!--            </template>-->
                <el-button @click="comDialog = true" size="mini" plain round>{{ txt[prefs.lang].change }}</el-button>
            </p>

            <p>
                {{ txt[prefs.lang].lang }}:
                <el-select v-model="prefs.lang" size="small">
                    <el-option label="Укр" value="uk" @click="prefs.lang='uk'" />
                    <el-option label="Рус" value="ru" @click="prefs.lang='ru'" />
                </el-select>
            </p>

            <el-popconfirm
                v-if="!user && me && me.email && /@/.test(me.email)"
                :title="txt[prefs.lang].reset+'?'"
                :confirmButtonText=txt[prefs.lang].reset
                :cancelButtonText=txt[prefs.lang].no_thanks
                @confirm="$emit('reset', me.email), resetLoading = true"
            >
                <template #reference>
                    <el-button size="small" :disabled="resetLoading" plain round>{{ txt[prefs.lang].reset }}</el-button>
                </template>
            </el-popconfirm>
            &nbsp;

            <el-popconfirm
                v-if="!user"
                :title="txt[prefs.lang].logout+'?'"
                :confirmButtonText=txt[prefs.lang].logout
                :cancelButtonText=txt[prefs.lang].no_thanks
                @confirm="$emit('logout'), logoutLoading = true"
            >
                <template #reference>
                    <el-button size="small" type="danger" :loading="logoutLoading" plain round>{{ txt[prefs.lang].logout }}</el-button>
                </template>
            </el-popconfirm>

            <h3>{{ txt[prefs.lang].your_orders }}</h3>

        </div>

        <div class="loader" v-if="loading" v-loading="loading"></div>

        <table v-if="orders && orders.length " class="table_clickable_tr">
            <tr>
                <th v-if="user">ID</th>
                <th>Дата</th>
                <th>Статус</th>
                <th class="hidden-xs-only">Оплата</th>
                <th class="hidden-xs-only">Сума</th>
                <th v-if="user && me.id < 1">Хто</th>
                <th v-if="user">Звдки</th>
            </tr>
            <tr v-for="order in orders" @click="toOrder(order.hash)">
                <th v-if="user">{{ order.id }}</th>
                <td>{{ order.placed }}</td>
                <td v-if="!order.canceled">
                    <template v-if="!order.tracking && !order.whaitavail">{{ txt[prefs.lang].in_process }}</template>
                    <template v-else-if="!order.tracking && order.whaitavail">{{ txt[prefs.lang].waiting }}</template>
                    <template v-else-if="order.tracking == '1'">{{ txt[prefs.lang].dispatched }}</template>
                    <template v-else-if="order.tracking == '2'">{{ txt[prefs.lang].delivered }}</template>
                    <template v-else-if="order.tracking">{{ txt[prefs.lang].shipped }}</template>
                </td>
                <td v-else>{{ txt[prefs.lang].canceled }}</td>
                <td class="hidden-xs-only">
                    <template v-if="order.canceled"></template>
                    <template v-else-if="order.sum_to_pay > 2">{{ txt[prefs.lang].to_pay }}: {{ order.sum_to_pay }} грн</template>
                    <template v-else>{{ txt[prefs.lang].paid }} <i class="el-icon-check"></i></template>
                </td>
                <td class="hidden-xs-only">{{ order.total }} грн</td>
                <td v-if="user && me.id < 1">{{ order.contact ? order.contact.firstname : '' }} {{ order.contact ? order.contact.lastname : '' }}</td>
                <td v-if="user">{{ order.source ? order.source.uk : '–' }}</td>
    <!--            <td><el-button type="success" plain round size="mini">{{ txt[prefs.lang].duplicate }}</el-button></td>-->
            </tr>
        </table>
        <div v-else class="centered">
            <p>{{ txt[prefs.lang].no_orders }}</p>
            <router-link to="/products/all"><el-button type="success" round>{{ txt[prefs.lang].all_products }}</el-button></router-link>
        </div>

    </template>

    <template v-else>
        <h1 v-if="user">Нужно выбрать пользователя! ⬆</h1>

        <template v-else>
            <h1>{{ txt[prefs.lang].pls_login }}:</h1>
            <p class="centered"><el-button @click="$emit('showLogin')" type="success" round>{{ txt[prefs.lang].login }}</el-button></p>
        </template>
    </template>


    <el-dialog
      :title=txt[prefs.lang].change_commethod
      v-model="comDialog"
      :before-close="onComClose"
      :fullscreen="smallScreen">
<!--        :before-close="onComChange"-->

        <el-form>
            <el-form-item :label=txt[prefs.lang].commethod.t prop="commethod">
                <el-input v-model="commethod.username" @keydown.enter="changeComMethod" :placeholder="txt[prefs.lang].commethod[commethod.source_id].i" :disabled="commethod.source_id == 2">
                    <template #prepend>
                        <el-select v-model="commethod.source_id" @change="onComChange(0)" :placeholder=txt[prefs.lang].commethod.t style="width: 120px; cursor: pointer !important;">
<!--                            <el-option v-for="(opt, i) in txt[prefs.lang].commethod" :value=i :label=opt.t />-->
                            <el-option value="2" :label=txt[prefs.lang].commethod[2].t />
                            <el-option value="3" :label=txt[prefs.lang].commethod[3].t />
                            <el-option value="6" :label=txt[prefs.lang].commethod[6].t />
                            <el-option value="5" :label=txt[prefs.lang].commethod[5].t />
                            <el-option value="9" :label=txt[prefs.lang].commethod[9].t />
                            <el-option value="8" :label=txt[prefs.lang].commethod[8].t />
                            <el-option value="7" :label=txt[prefs.lang].commethod[7].t />
                        </el-select>
                    </template>
                </el-input>
            </el-form-item>
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="onComClose" plain round>{{ txt[prefs.lang].cancel }}</el-button>
            <el-button type="success" @click="changeComMethod" :loading="comLoading" round>{{ txt[prefs.lang].save }}</el-button>
          </span>
        </template>
    </el-dialog>

</template>

<script>
import useCart from "@/use/cart"
import {watch, reactive, ref} from "vue"
import {txt} from '@/use/txt'
import router from '@/router/index'
import {useQuery, useResult, useMutation} from "@vue/apollo-composable"
import {gql} from "@apollo/client"

export default {
    setup(props, context) {

        const { me, user, prefs, message } = useCart()
        const logoutLoading = ref(false)
        const resetLoading = ref(false)

        if (!me.value && !user.value) context.emit('showLogin')

        function toOrder(id) {
            router.push({ path: '/order/'+id})
        }

        // if (!user.value && me.value) {
        const {result, loading, error} = useQuery(gql`{
            me {
                orders: my_orders {
                    hash
                    placed
                    tracking
                    sum_to_pay
                    canceled
                    total
                    whaitavail
                }
            }
        }`, {}, {fetchPolicy: "network-only"})
        // const orders = useResult(result, null, data => data.me.orders)
        // }
        // else {
        //     const loading = ref(false)
        // }
        // elseif (user.value && me.value) {
        //     const orders = me.value.orders
        // }
        const orders = user.value && me.value && me.value.orders ? ref(me.value.orders) : ref(useResult(result, null, data => data.me.orders))

        watch(me, () => {
            orders.value = me.value ? me.value.orders : []
        })

        const smallScreen = ref(window.innerWidth < 800)

        const comDialog = ref(false)

        const commethod = reactive({ source_id: "2", username: "" })
        // const commethod = me.value && me.value.socials.find(i => i.status === 2) ?
        //     reactive(JSON.parse(JSON.stringify(me.value.socials.find(i => i.status === 2))))
        //     : reactive({ source_id: "2", username: me.value ? me.value.email : null })
        // const comcontact = ref(null)

        function onComChange(real = false) {
            if (!me.value) return
            let social = real ? me.value.socials.find(i => i.status === 2)
                : me.value.socials.find(i => i.source_id == commethod.source_id)
            if (real) commethod.source_id = social ? social.source_id : "2"
            // console.log(commethod)

            switch (commethod.source_id) {
                case "2": commethod.username = me.value.email; break; // Email
                case "3": case "8": case "9":
                  commethod.username = social && social.phone ? '+380'+social.phone.number : '+380'+me.value.last_phone; break; // Phone, Viber, Signal
                case "5": commethod.username = social ? social.username ? '@'+social.username : 'https://www.facebook.com/profile.php?id='+social.profile_id : null; break; // Fb
                case "6": commethod.username = social ? '@'+social.username : null; break; // Insta
                case "7": commethod.username = social ?
                    social.phone ? '+380'+social.phone.number :
                    social.username ? social.username :
                    '+380'+me.value.last_phone :
                    null; break; // Telegram
            }
            // console.log('onComChange')
            // commethod.username = social ? social.username : null
        }

        if (me.value) onComChange(1)
        watch(me, () => {
            onComChange(1)
        })

        function onComClose() {
            onComChange(1)
            comDialog.value = false
        }

        const { mutate: change_com, loading: comLoading } = user.value ? useMutation(gql `
          mutation($username: String!, $source_id: ID!, $customer_id: ID!) {
              socials: changeComMethod_(username: $username, source_id: $source_id, customer_id: $customer_id) {
                  source_id
                  profile_id
                  username
                  phone {
                      number
                  }
                  status
              }
      }`) : useMutation(gql `
          mutation($username: String!, $source_id: ID!) {
              socials: changeComMethod(username: $username, source_id: $source_id) {
                  source_id
                  profile_id
                  username
                  phone {
                      number
                  }
                  status
              }
      }`)

        function changeComMethod() {
            change_com({ username: commethod.username, source_id: commethod.source_id, customer_id: me.value.id }).then(r => {
                if (r.data.socials.length) {
                    comDialog.value = false
                    message(txt[prefs.lang].saved, txt[prefs.lang].all_saved, 'success')
                }
                else {
                    message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'warning')
                }
            }).catch(e => {
                // console.log(e)
                message(txt[prefs.lang].error, txt[prefs.lang].wrong, 'error')
            })

        }

        return { me, user, prefs, orders, loading, txt, toOrder, router, comDialog, commethod, onComChange, onComClose, changeComMethod, comLoading, smallScreen, logoutLoading, resetLoading }
    }
}
</script>